import React from "react";

const SvgNumEvangelicals = props => (
  <svg width={48} height={48} {...props}>
    <defs>
      <circle id="num-evangelicals_svg__a" cx={24} cy={24} r={24} />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="num-evangelicals_svg__b" fill="#fff">
        <use xlinkHref="#num-evangelicals_svg__a" />
      </mask>
      <use
        fill="#D8D8D8"
        fillRule="nonzero"
        xlinkHref="#num-evangelicals_svg__a"
      />
      <g mask="url(#num-evangelicals_svg__b)" fill="#966274" fillRule="nonzero">
        <path d="M-60-22h160v80H-60z" />
      </g>
      <path
        d="M33.5 18c0-1.333 2-1.333 2 0v5.816a1 1 0 01-1 1H29c-1.333 0-1.333-2 0-2h4.5V18zm2 17c0 1.333-2 1.333-2 0V23.816a1 1 0 011-1H40c1.333 0 1.333 2 0 2h-4.5V35zM23.5 10c0-1.333 2-1.333 2 0v5.23a1 1 0 01-1 1H19c-1.333 0-1.333-2 0-2h4.5V10zm2 17c0 1.333-2 1.333-2 0V15.23a1 1 0 011-1H30c1.333 0 1.333 2 0 2h-4.5V27zM15.5 16v4.23H20c1.333 0 1.333 2 0 2h-4.5V33c0 1.333-2 1.333-2 0V22.23H9c-1.333 0-1.333-2 0-2h4.5V16c0-1.333 2-1.333 2 0z"
        fill="#FFF"
        fillRule="nonzero"
        mask="url(#num-evangelicals_svg__b)"
      />
    </g>
  </svg>
);

export default SvgNumEvangelicals;
