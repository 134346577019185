import React from "react";

const SvgRegionalAccess = props => (
  <svg width={48} height={48} {...props}>
    <defs>
      <circle id="regional-access_svg__a" cx={24} cy={24} r={24} />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="regional-access_svg__b" fill="#fff">
        <use xlinkHref="#regional-access_svg__a" />
      </mask>
      <use
        fill="#D8D8D8"
        fillRule="nonzero"
        xlinkHref="#regional-access_svg__a"
      />
      <g mask="url(#regional-access_svg__b)" fill="#536980" fillRule="nonzero">
        <path d="M-60-22h160v80H-60z" />
      </g>
      <path
        d="M13.5 31a6.5 6.5 0 110-13 6.5 6.5 0 010 13zm0-2a4.5 4.5 0 100-9 4.5 4.5 0 000 9z"
        fill="#FFF"
        fillRule="nonzero"
        mask="url(#regional-access_svg__b)"
      />
      <path
        d="M19 24.5a1 1 0 010-2h21a1 1 0 010 2H19z"
        fill="#FFF"
        fillRule="nonzero"
        mask="url(#regional-access_svg__b)"
      />
      <path
        d="M36 23.5a1 1 0 012 0V31a1 1 0 01-2 0v-7.5zM31 23.5a1 1 0 012 0v6.124a1 1 0 11-2 0V23.5z"
        fill="#FFF"
        fillRule="nonzero"
        mask="url(#regional-access_svg__b)"
      />
    </g>
  </svg>
);

export default SvgRegionalAccess;
