import React from "react";

const SvgFilterIcon = props => (
  <svg width={18} height={12} {...props}>
    <path
      d="M1 1h16M3 6h12M5 11h8"
      fillRule="nonzero"
      stroke="#8C9B94"
      strokeWidth={2}
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgFilterIcon;
