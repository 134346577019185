import React from "react";

const SvgPrayerIcon = props => (
  <svg width={48} height={49} {...props}>
    <g stroke="#8C9B94" fill="none" fillRule="evenodd">
      <ellipse cx={24} cy={24.5} rx={23.5} ry={24} />
      <g strokeLinecap="round" strokeWidth={1.039}>
        <path d="M26.002 20.19l-6.933-5.898c-1.141 0-3.791 1.853-2.432 7.1.266 1.026 1.415 2.442 3.447 4.245.804 2.19 1.682 3.585 2.633 4.187 1.427.903 4.024 2.192 5.49 3.738 1.95-.344 3.206-1.59 3.77-3.738l-.847-.903c-.357-2.082-1.004-5.052-2.248-6.696-1.245-1.643-2.064-4.408-2.88-6.08-.946 0-1.783.23-2.166 1.975M24.765 33.556c-.616.759-3.53 1.546-5.323.464 0-1.607-.297-3.16-1.49-4.202-1.561-1.365-2.186-3.27-1.874-5.716M17.032 15.883l7.226 5.818M16.258 18.534l6.194 5.038" />
      </g>
    </g>
  </svg>
);

export default SvgPrayerIcon;
