import React, { Component } from 'react';
import './Home.css';
import { HomeSwiper } from '../HomeSwiper/HomeSwiper'
import { Link } from 'react-router-dom';

import section1 from '../../images/home-profile.png';
import section2 from '../../images/home-sort.png';

export class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortKey: 'NONE',
      isSortReverse: false,      
    };
  }

  render() {

      return(
      <main id="main_content">

        <div className="home_banner full_w">
          <div className="flex">
            <div className="left">
              <h1><u>60</u> Countries.<br /><u>520</u> Million<br />Believers.<br /><u>Unprecedented</u><br />Access.</h1>
              <p>God is growing His kingdom in areas with strategic access to unreached people groups. The Mobilization Index shines a light on how we can all work together to advance the gospel to unreached people groups (UPGs).</p>
              <div className="video_popup rounded">
                <a href="#" className="bgicc">
                </a>
              </div>
            </div>
            <div className="right">
                <HomeSwiper />
            </div>
          </div>
        </div>

        <div className="two_col_section img_right full_w">
          <div className="container">
            <div className="left">
              <figure className="flex">
                <img src={section1} alt="" />
              </figure>
            </div>
            <div className="right">
              <div className="text">
                <p className="overtext">Country + Region Profiles</p>
                <h4>Explore the unique sending potential of countries with one million or more believers.</h4>
                <Link className="link" to="/map">Choose a country to explore</Link>
                <div className="btn">
                  <Link to="/map"></Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="two_col_section sort_comp img_left full_w">
          <div className="container">
            <div className="left">
              <figure className="flex">
                <img src={section2} alt="" />
              </figure>
            </div>
            <div className="right">

              <div className="text">
                <p className="overtext">Sort + Compare</p>
                <h4>Make informed, strategic decisions based on your mission vision.</h4>
                <Link className="link" to="/compare">Identify the countries around the world with the greatest mission sending potential</Link>
                <div className="btn">
                  <Link to="/compare"></Link>
                </div>
              </div>
            </div>
          </div>
        </div>

      </main>
    );  
  }  
}