import React from 'react';
import './Definitions.css';

const Sidebar = () =>
  <aside id="sidebar" style={{ paddingTop: '2.6em' }}>
    <div className="sticky_menu">
      <div className="info_box">
        <div className="anchors">
          <p><a href="#evangelicals">Number of Evangelicals</a></p>
          <p><a href="#bridges">Cultural Bridges to UPGs</a></p>
          <p><a href="#regional">Regional Access to UPGs</a></p>
          <p><a href="#freedoms">Religious Freedoms</a></p>
          <p><a href="#prosperity">Legatum Prosperity Rank</a></p>
          <p><a href="#sending">Current Sending Abroad</a></p>
          <p><a href="#mobilization">Mobilization Potential</a></p>
          <p><a href="#custom_mobilization">Custom Mobilization Potential</a></p>
        </div>
        <div className="anchors">
          <p><a href="#index_country">Index Country</a></p>
          <p><a href="#country">Country</a></p>
          <p><a href="#evangelical">Evangelical</a></p>
          <p><a href="#upg">Unreached Peopple Group (UPG)</a></p>
        </div>
        <p className="to_top mobile_hidden"><a href="#header"><span className="icon arrow_up_alt"></span>Back to Top</a></p>
      </div>
    </div>
  </aside>

export const Definitions = () => {

  return (
    <React.Fragment>

      <div className="page_title full_w">
        <div className="container">
          <h1>Definitions</h1>
        </div>
      </div>

      <Sidebar />

      <main id="main_content">

        <div className="narrow_content">

          <div className="sub_index" id="evangelicals">
            <div className="icon_text flex">
              <div className="dot large evangelicals"></div>
              <div className="text">
                <h3>Number of Evangelicals</h3>
              </div>
            </div>
            <p>Omne animal, simul atque haec ratio late patet in oculis quidem exercitus quid ex eo est primum igitur, quid bonum esse admonere interesse enim ad respondendum reddidisti quorum nihil est, qui dolorem ipsum autem nusquam hoc epicurus in ea quid est cur nec in liberos.</p>
            <p>Quid ex ea voluptate et harum quidem faciunt, ut alterum esse expetendam et dolorem? sunt autem quibusdam et quasi architecto beatae vitae dicta sunt, explicabo nemo enim ad eam non recusandae itaque aiunt hanc quasi architecto beatae vitae dicta sunt, fecerint, virtutem iis per se.</p>
          </div>

          <div className="sub_index" id="bridges">
            <div className="icon_text flex">
              <div className="dot large bridges"></div>
              <div className="text">
                <h3>Cultural Bridges to UPGs</h3>
              </div>
            </div>
            <p>Omne animal, simul atque haec ratio late patet in oculis quidem exercitus quid ex eo est primum igitur, quid bonum esse admonere interesse enim ad respondendum reddidisti quorum nihil est, qui dolorem ipsum autem nusquam hoc epicurus in ea quid est cur nec in liberos.</p>
            <p>Torquatos nostros? quos tu tam crudelis fuisse, nihil est, qui dolorem aspernari ut dolore disputandum putant sed ut aut petat aut petat aut ad respondendum reddidisti quorum nihil ut dolore disputandum putant sed ut calere ignem, nivem esse expetendam et dolorem? sunt autem nusquam hoc.</p>
          </div>

          <div className="sub_index" id="regional">
            <div className="icon_text flex">
              <div className="dot large regional"></div>
              <div className="text">
                <h3>Regional Access to UPGs</h3>
              </div>
            </div>
            <p>Omne animal, simul atque haec ratio late patet in oculis quidem exercitus quid ex eo est primum igitur, quid bonum esse admonere interesse enim ad respondendum reddidisti quorum nihil est, qui dolorem ipsum autem nusquam hoc epicurus in ea quid est cur nec in liberos.</p>
            <p>Torquatos nostros? quos tu tam crudelis fuisse, nihil est, qui dolorem aspernari ut dolore disputandum putant sed ut aut petat aut petat aut ad respondendum reddidisti quorum nihil ut dolore disputandum putant sed ut calere ignem, nivem esse expetendam et dolorem? sunt autem nusquam hoc.</p>
            <p>Quid ex ea voluptate et harum quidem faciunt, ut alterum esse expetendam et dolorem? sunt autem quibusdam et quasi architecto beatae vitae dicta sunt, explicabo nemo enim ad eam non recusandae itaque aiunt hanc quasi architecto beatae vitae dicta sunt, fecerint, virtutem iis per se.</p>
          </div>

          <div className="sub_index" id="freedoms">
            <div className="icon_text flex">
              <div className="dot large freedom"></div>
              <div className="text">
                <h3>Religious Freedoms</h3>
              </div>
            </div>
            <p>Omne animal, simul atque haec ratio late patet in oculis quidem exercitus quid ex eo est primum igitur, quid bonum esse admonere interesse enim ad respondendum reddidisti quorum nihil est, qui dolorem ipsum autem nusquam hoc epicurus in ea quid est cur nec in liberos.</p>
          </div>

          <div className="sub_index" id="prosperity">
            <div className="icon_text flex">
              <div className="dot large prosperity"></div>
              <div className="text">
                <h3>Prosperity</h3>
              </div>
            </div>
            <p>Prosperity is more than just the accumulation of material wealth, it is also the joy of everyday life and the prospect of an even better life in the future. This is true for individuals as well as nations.</p>
            <p>The Legatum Institute exists to promote policies that create pathways from poverty to prosperity. We believe that every individual has immense personal value and potential and, for us, prosperity requires that people have the opportunity to fulfil that potential and to help others realise theirs. That means prosperity can never just be about material wealth; at its core it also entails personal and social wellbeing, such as having a home, an education and family and friends who care for us. The journey towards prosperity is therefore not just about what we contribute, but about who we become.</p>
          </div>

          <div className="sub_index" id="sending">
            <div className="icon_text flex">
              <div className="dot large sending"></div>
              <div className="text">
                <h3>Current Sending Abroad</h3>
              </div>
            </div>
            <p>Omne animal, simul atque haec ratio late patet in oculis quidem exercitus quid ex eo est primum igitur, quid bonum esse admonere interesse enim ad respondendum reddidisti quorum nihil est, qui dolorem ipsum autem nusquam hoc epicurus in ea quid est cur nec in liberos.</p>
            <p>Torquatos nostros? quos tu tam crudelis fuisse, nihil est, qui dolorem aspernari ut dolore disputandum putant sed ut aut petat aut petat aut ad respondendum reddidisti quorum nihil ut dolore disputandum putant sed ut calere ignem, nivem esse expetendam et dolorem? sunt autem nusquam hoc.</p>
          </div>

          <div className="sub_index" id="mobilization">
            <div className="icon_text flex">
              <div className="dot large mobilization"></div>
              <div className="text">
                <h3>Mobilization Potential</h3>
              </div>
            </div>
            <p>Omne animal, simul atque haec ratio late patet in oculis quidem exercitus quid ex eo est primum igitur, quid bonum esse admonere interesse enim ad respondendum reddidisti quorum nihil est, qui dolorem ipsum autem nusquam hoc epicurus in ea quid est cur nec in liberos.</p>
            <p>Torquatos nostros? quos tu tam crudelis fuisse, nihil est, qui dolorem aspernari ut dolore disputandum putant sed ut aut petat aut petat aut ad respondendum reddidisti quorum nihil ut dolore disputandum putant sed ut calere ignem, nivem esse expetendam et dolorem? sunt autem nusquam hoc.</p>
          </div>

          <div className="sub_index" id="custom_mobilization">
            <div className="icon_text flex">
              <div className="dot large custom_mobilization"></div>
              <div className="text">
                <h3>Custom Mobilization Potential</h3>
              </div>
            </div>
            <p>Omne animal, simul atque haec ratio late patet in oculis quidem exercitus quid ex eo est primum igitur, quid bonum esse admonere interesse enim ad respondendum reddidisti quorum nihil est, qui dolorem ipsum autem nusquam hoc epicurus in ea quid est cur nec in liberos.</p>
            <p>Torquatos nostros? quos tu tam crudelis fuisse, nihil est, qui dolorem aspernari ut dolore disputandum putant sed ut aut petat aut petat aut ad respondendum reddidisti quorum nihil ut dolore disputandum putant sed ut calere ignem, nivem esse expetendam et dolorem? sunt autem nusquam hoc.</p>
            <p>Quid ex ea voluptate et harum quidem faciunt, ut alterum esse expetendam et dolorem? sunt autem quibusdam et quasi architecto beatae vitae dicta sunt, explicabo nemo enim ad eam non recusandae itaque aiunt hanc quasi architecto beatae vitae dicta sunt, fecerint, virtutem iis per se.</p>
          </div>


          <div className="sub_index" id="index_country">
            <p className="large"><b>Index Country</b></p>
            <p>Several years ago, a Brazilian commercial depicts Rio's famous Sugarloaf Mountain shaking and rumbling and ultimately revealed to be a sleeping giant. The commercial ends with the statement, “The giant is no longer sleeping.”  Could this commercial for Irish Whisky also be a metaphor for the Brazilian missions movement?</p>
            <p>In the early part of the 2000’s, it was estimated that Brazil had sent approximately 3500 missionaries. Most were serving among reached people groups in places such as Angola, Mozambique, Portugal, or the U.S. With an estimated evangelical population of 50 million, this represents less than one missionary per 700,000 Brazilian evangelicals.</p>
            <p>However, recent research conducted by the Brazilian mission network AMTB, suggests the total number of Brazilain cross-cultural workers has increased to more than 17,000, with close to 50% serving outside Brazil. Additionally, where Brazilians are serving has shifted. According to AMTB, Brazilian missionaries are increasingly working among the world’s least-reached people groups. Muslim peoples are currently the missionary focus of 21% of Brazil’s cross-cultural workers.</p>
            <p>How did this tremendous shift come about? Why are so many Brazilians being sent out to the to proclaim Christ among the unreached people? According to AMTB’s own research, the growth of mission mobilization as a primary factor in the growth of the Brazilian mission movement. </p>
            <p>God has used such mobilization movements such as Perspectivas no Movimento Cristao Mundial (the Portuguese version of the popular Perspectives movement), Povos e Linguas, and Vocare to ignite a passion for His global purposes among the unreached. The Brazilian church is increasingly focused on missions to the unreached and God is raising up new laborers for His harvest. </p>
            <p>Brazil is a testimony of what happens when God’s people are mobilized for His purposes. May our Father wake up even more sleeping giants from among His church in Africa, Asia, Latin America, and beyond.</p>
          </div>

          <div className="sub_index" id="country">
            <p className="large"><b>Country</b></p>
            <p><b>Pray</b> for the ongoing efforts of the various mobilization ministries in Brazil. Pray for believers to find their most strategic role in seeing Christ proclaimed among the nations.</p>
          </div>

          <div className="sub_index" id="evangelical">
            <p className="large"><b>Evangelical</b></p>
            <p>The Brazilian church is massive. If just 0.1%, or 1 in 1000 evangelicals, were to be mobilized to go to the Unreached, the Lord’s Harvest would see 50,000 new missionaries raised up! This would be one of the largest mission movements in the history of the New Testament church. Coupled with Brazil’s close Cultural Distance to many UPG nations, Brazilians are prime candidates for mobilization. Their distance from UPG nations and low access could make mobilization that focuses on the Unreached more difficult. But with quality prayer resources in Portuguese and a growing number of Muslim immigrants and refugees, Brazilians have even more opportunities to participate in praying for and welcoming the Unreached. However, the growing financial crisis in Brazil means that current and potential missionaries could face obstacles in being fully funded. Brazilian missionaries would benefit from effective and biblical based support raising trainings. Furthermore, mission mobilization that gives special attention to and cast vision for the role of Sending are more important than ever.</p>
            <p>Lastly, mission Mobilization is on the rise in Brazil. Perspectivas, the Portuguese version of the world Christian discipleship course Perspectives on the world Christian movement, has seen tremendous growth in the past few years.  Not only are the numbers of participants on the rise (average className size is above 80) but Perspectivas has more than XX course in XX states.</p>
            <p>Like Perspectivas there is a growing number of ministries in Brazil that have a strong mobilization emphasis and give special focus to the Unreached. AMTB, Povos e Linguas, and VOCARÉ, are a few examples of national level ministries.  These groups are not just mission organizations, but networks of pastors, mission leaders, mission agencies, churches, and denominations. As nationwide networks, they have banded together in unity for the purpose of seeing missionaries sent out to the Unreached, Christ proclaimed, and churches planted.</p>
          </div>

          <div className="sub_index" id="upg">
            <p className="large"><b>Unreached People Group (UPG)</b></p>
            <p>The Brazilian church is massive. If just 0.1%, or 1 in 1000 evangelicals, were to be mobilized to go to the Unreached, the Lord’s Harvest would see 50,000 new missionaries raised up! This would be one of the largest mission movements in the history of the New Testament church. Coupled with Brazil’s close Cultural Distance to many UPG nations, Brazilians are prime candidates for mobilization. Their distance from UPG nations and low access could make mobilization that focuses on the Unreached more difficult. But with quality prayer resources in Portuguese and a growing number of Muslim immigrants and refugees, Brazilians have even more opportunities to participate in praying for and welcoming the Unreached. However, the growing financial crisis in Brazil means that current and potential missionaries could face obstacles in being fully funded. Brazilian missionaries would benefit from effective and biblical based support raising trainings. Furthermore, mission mobilization that gives special attention to and cast vision for the role of Sending are more important than ever.</p>
            <p>Lastly, mission Mobilization is on the rise in Brazil. Perspectivas, the Portuguese version of the world Christian discipleship course Perspectives on the world Christian movement, has seen tremendous growth in the past few years.  Not only are the numbers of participants on the rise (average className size is above 80) but Perspectivas has more than XX course in XX states.</p>
            <p>Like Perspectivas there is a growing number of ministries in Brazil that have a strong mobilization emphasis and give special focus to the Unreached. AMTB, Povos e Linguas, and VOCARÉ, are a few examples of national level ministries.  These groups are not just mission organizations, but networks of pastors, mission leaders, mission agencies, churches, and denominations. As nationwide networks, they have banded together in unity for the purpose of seeing missionaries sent out to the Unreached, Christ proclaimed, and churches planted.</p>
            <p><b>Praise God</b> for the growth of the evangelical church in Brazil.</p>
            <p><b>Pray</b> that the church’s roots would grow deep and strong.</p>
          </div>

        </div>
        
      </main>


    </React.Fragment>
  );
}  

