import React, { useState, useEffect, useCallback } from 'react';
import './HomeSwiper.css';
import Swiper from 'react-id-swiper';
import 'react-id-swiper/lib/styles/css/swiper.css';

import slide1 from '../../images/home-slide1-517302398.jpg';
import slide2 from '../../images/home-slide2-1153951190.jpg';
import slide3 from '../../images/home-slide3-645173316.jpg';

const SlideItem = ({idx, image, description}) =>
  <div className="swiper-slide" data-desc={description}>
    <div className="slide_wraper">
      <img src={image} alt={"slide-" + idx} />
    </div>
  </div>

const SlideDecriptions = [
  "Though not the most Christian by percentage, Nigeria is home to the largest population of Christians in Africa.",
  "The Brazilian church is massive. If just 0.1% of Evangelicals were mobilized, 50,000 new missionaries would be raised up!",
  "Indonesia is home to 60 million Evangelicals, who have access to 120 million Muslims who have never heard the gospel."
];

export const HomeSwiper = () => {

  // Swiper instance
  const [swiper, updateSwiper] = useState(null);

  // Slides current description
  const [currentDescr, updateCurrentDescr] = useState(SlideDecriptions[0]);

  // Params definition
  const swiperParams = {
    // pagination: {
    //   el: '.swiper-pagination',
    //   type: 'bullets',
    //   clickable: true
    // },
    // navigation: {
    //   nextEl: '.swiper-button-next',
    //   prevEl: '.swiper-button-prev'
    // },

    slidesPerView: 'auto',
    // spaceBetween: 55,
    autoplay: true,
    speed: 1500,
    loop: true,
    draggable: false,
    touchRatio: 0,
    simulateTouch: false,
    getSwiper: updateSwiper // Get swiper instance callback 
  }
  
  const updateDescr = useCallback(() => updateCurrentDescr(( swiper.slides[ swiper.realIndex ] ).getAttribute('data-desc')), [
    swiper
  ]);

  // Add eventlisteners for swiper after initializing
  useEffect(() => {
    if (swiper !== null) {
      swiper.on("slideChange", updateDescr);
    }

    return () => {
      if (swiper !== null) {
        swiper.off("slideChange", updateDescr);        
      }
    };
  }, [swiper, updateDescr]);

  return (

    <div className="home_slider">

      <Swiper {...swiperParams}>
        <SlideItem idx={1} image={slide1} description={SlideDecriptions[0]} />
        <SlideItem idx={2} image={slide2} description={SlideDecriptions[1]} />
        <SlideItem idx={3} image={slide3} description={SlideDecriptions[2]} />
      </Swiper>

      <div className="slide_text">
        <div className="dot evangelicals"></div>
        <div className="infowindow">
          <p id="iw_text">{currentDescr}</p>
        </div>
      </div>

    </div>

  );
};