import React from "react";

const SvgReligiousFreedom = props => (
  <svg width={48} height={48} {...props}>
    <defs>
      <circle id="religious-freedom_svg__a" cx={24} cy={24} r={24} />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="religious-freedom_svg__b" fill="#fff">
        <use xlinkHref="#religious-freedom_svg__a" />
      </mask>
      <use
        fill="#D8D8D8"
        fillRule="nonzero"
        xlinkHref="#religious-freedom_svg__a"
      />
      <g
        mask="url(#religious-freedom_svg__b)"
        fill="#8C9B94"
        fillRule="nonzero"
      >
        <path d="M-60-22h160v80H-60z" />
      </g>
      <path
        d="M11.005 22.37c-1.6-4.078-1.086-9.09 1.164-11.283a1 1 0 011.623.337c2.002 4.876 4.511 7.444 8.498 9.561.664.353.79.415 2.514 1.247 1.22.589 1.945.96 2.7 1.402a1 1 0 11-1.009 1.727c-.697-.408-1.385-.76-2.56-1.328-1.756-.847-1.885-.91-2.583-1.281-3.894-2.068-6.572-4.597-8.658-8.884-.834 1.983-.87 5.114.173 7.772 1.598 4.071 5.167 6.248 10.567 5.496.996-.138 1.55 1.114.779 1.758-1.963 1.639-2.9 2.943-2.9 3.767a1 1 0 01-.763.972c-2.177.53-3.576 1.057-4.106 1.481.001.066.012.159.036.273.077.364.266.85.544 1.398.393.776.941 1.62 1.36 2.145.072-.05.151-.111.238-.182.475-.385 1.09-.997 1.854-1.831.156-.17.319-.35.49-.542.384-.43 1.632-1.858 1.84-2.09C23.908 33.05 23.96 33 24.536 33c.601 0 1.778-.313 3.002-1.079 2.009-1.256 3.706-3.435 4.862-6.796.668-1.941 2.274-3.04 3.934-3.371-1.215-1.33-3.381-1.234-5.636.962-.694.676-1.847.07-1.684-.885.269-1.568.316-1.95.666-5.102.248-2.226.447-3.425.852-4.752.11-.36.233-.716.371-1.068-4.036.165-6.409 3.75-6.331 8.073a1 1 0 01-2 .036c-.105-5.875 3.676-10.896 9.98-10.019a1 1 0 01.753 1.447 12.69 12.69 0 00-.86 2.115c-.36 1.176-.542 2.278-.777 4.39a269.98 269.98 0 01-.319 2.788c3.257-1.796 6.487-.62 7.597 2.623a1 1 0 01-1.085 1.314c-1.315-.184-3.022.507-3.57 2.1-1.303 3.787-3.289 6.338-5.692 7.841-1.378.862-2.75 1.283-3.713 1.367-.138.137-.33.343-.588.632-.198.222-1.447 1.651-1.84 2.091-.177.198-.345.384-.507.561-2.479 2.705-3.519 3.549-4.649 2.448-1.782-1.736-3.738-5.596-2.34-6.957.725-.707 2.18-1.31 4.453-1.903.204-.817.71-1.672 1.51-2.582-4.854-.161-8.287-2.741-9.92-6.903z"
        fill="#FFF"
        fillRule="nonzero"
        mask="url(#religious-freedom_svg__b)"
      />
    </g>
  </svg>
);

export default SvgReligiousFreedom;
