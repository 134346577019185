import React from "react";

const SvgObstaclesIcon = props => (
  <svg width={48} height={48} {...props}>
    <defs>
      <path d="M21.75 15v18" id="obstacles-icon_svg__a" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        d="M12.197 22.965a.814.814 0 01.593-.243h19.495l-3.357-3.354c-.395-.357-.403-.722-.025-1.094.379-.373.75-.365 1.111.024l4.74 4.667a.47.47 0 01.197.243.905.905 0 010 .584.47.47 0 01-.198.243l-4.739 4.667c-.362.389-.732.397-1.11.024-.379-.372-.37-.737.024-1.094l3.357-3.354H12.79A.751.751 0 0112 23.5c0-.227.066-.405.197-.535z"
        fill="#8C9B94"
      />
      <circle stroke="#8C9B94" cx={24} cy={24} r={23.5} />
      <g strokeLinecap="round" fillRule="nonzero">
        <use stroke="#FFF" strokeWidth={7} xlinkHref="#obstacles-icon_svg__a" />
        <use
          stroke="#8C9B94"
          strokeWidth={1.75}
          xlinkHref="#obstacles-icon_svg__a"
        />
      </g>
    </g>
  </svg>
);

export default SvgObstaclesIcon;
