import React, { useState, useEffect } from 'react';
import Markdown from 'markdown-to-jsx';
import { Link } from 'react-router-dom';
import './Profile.css';
import classNames from 'classnames';

import {CountryItem} from '../layout/Nav/CountryItem';

import ObstaclesIcon from '../../icons/ObstaclesIcon.js';
import MovementsIcon from '../../icons/MovementsIcon.js';
import PrayerIcon from '../../icons/PrayerIcon.js';
import IprayedIcon from '../../icons/IprayedIcon.js';

import regionMap from '../../images/Brazils_Regional_Ac.png';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import { 
  PATH_IMG_BASE,
  REGIONS,
  strip
} from '../../constants';

const RangeItem = ({
  index,
  score,
  isCurrent
}) => {

  const currClass = classNames(
    { 'current': isCurrent }
  );

  const divStyle = {
    left: score + '%',
    backgroundPosition: '0 -' + index + 'em'
  };

  return (
    <span className={currClass} style={divStyle}></span>
  );
};

const RangeItemNonCurrent = ({
  index,
  countries
}) => {
  const scoreSwitch = (item) => {
    switch(index) {
      case 0:
        return item.number_of_evangelicals_score;
      case 1:
        return item.cultural_bridges_to_upgs_score;
      case 2:
        return item.regional_access_to_upgs_score;
      case 3:
        return item.religious_freedom_score;
      case 4:
        return item.prosperity_score;
      case 5:
        return item.current_sending_abroad_score;
      case 6:
        return item.mobilization_potential_score;        
      default:
        return null;
    }
  }
  return (
    countries.map( item =>
      <RangeItem key={item.id} index={index} score={scoreSwitch(item)} isCurrent={false} />
    )
  );
};

const Range = ({
  title,
  index,
  subindex,
  score,
  ...props
}) => {
  return (
    <div className="range_item one flex">
      <div className="range_title">
        <p>{title}</p>
      </div>
      <div className="range">
        <RangeItem index={index} score={score} isCurrent={true} /> 
        <RangeItemNonCurrent index={index} countries={props.countries} />
      </div>
    </div>
  );
};

const RangeBox = ({
  profile,
  country,
  ...props
}) => {

  return (  
    <div className="country_info_right flex">
      <div className="info_box">

        <div className="country">
          <CountryItem countryName={profile.country_name} countryCode={country.country_code} />
        </div>

        <div className="ranges">
          <Range title='Number of Evangelicals' index={0} subindex="number_of_evangelicals_score" score={country.number_of_evangelicals_score} {...props}></Range>
          <Range title='Cultural Bridges to UPGs' index={1} score={country.cultural_bridges_to_upgs_score} {...props}></Range>
          <Range title='Regional Access to UPGs' index={2} score={country.regional_access_to_upgs_score} {...props}></Range>
          <Range title='Religious Freedoms' index={3} score={country.religious_freedom_score} {...props}></Range>
          <Range title='Legatum Prosperity Rank' index={4} score={country.prosperity_score} {...props}></Range>
          <Range title='Current Sending' index={5} score={country.current_sending_abroad_score} {...props}></Range>
          <Range title='Mobilization Potential' index={6} score={country.mobilization_potential_score} {...props}></Range>
        </div>

        <div className="download flex">
          { profile.resource_link &&
            <a href={`${profile.resource_link}`} className="flex" target="_blank" rel="noopener noreferrer"><span className="icon_book_alt"></span><p>Download <i>{profile.resource_text}</i> for free and mobilize those around you</p></a>
          }
          { profile.presentation_link &&
            <a href={`${profile.presentation_link}`} className="flex" target="_blank" rel="noopener noreferrer"><span className="icon_easel"></span><p>Download a {profile.presentation_text} of this Profile</p></a>
          }
        </div>
        { profile.other_resources_link &&
          <a href={`${profile.other_resources_link}`} className="other_resources" target="_blank" rel="noopener noreferrer"><span className="icon_documents"></span>See other resources for {profile.country_name} at the Global Mobilization Network</a>
        } 
        
      </div>
    </div>
  );
}

const Info = ({
  country,
  profile
}) =>
  <div className="country_info_left flex">
    <div className="social_share_box">
      <ul className="social flex">
        <li className="facebook">
          <a href="#"><span className="social_facebook"></span></a>
        </li>
        <li className="twitter">
          <a href="#"><span className="social_twitter"></span></a>
        </li>
        <li className="mail">
          <a href="mailto:info@mobilization.org"><span className="icon_mail"></span></a>
        </li>
      </ul>
    </div>

    <div className="info_box">
      <p><strong className="db">Official Country Name:</strong> {profile.official_country_name}</p>
      <p><strong>Population:</strong> {country.population}</p>
      <p><strong>Region:</strong> <a href="#">{REGIONS[country.region_code]}</a></p>
      <p><strong>Government:</strong> {profile.government}</p>
      <p><strong className="db">Official Languages:</strong> {country.official_languages}</p>
      <p><strong className="db">Religious Demographics:</strong> {profile.religious_demographics}</p>
    </div>
  </div>

const Sidebar = ({
  country,
  profile
}) =>
  <aside id="sidebar">
    <div className="sticky_menu">
      <div className="info_box">
        <div className="country flex">
          <CountryItem countryName={profile.country_name} countryCode={country.country_code} />
        </div>
        <div className="anchors">
          <p><a href="#evangelicals">Number of Evangelicals</a></p>
          <p><a href="#bridges">Cultural Bridges to UPGs</a></p>
          <p><a href="#regional">Regional Access to UPGs</a></p>
          <p><a href="#freedom">Religious Freedoms</a></p>
          <p><a href="#prosperity">Legatum Prosperity Rank</a></p>
          <p><a href="#sending">Current Sending Abroad</a></p>
          <p><a href="#mobilization">Mobilization Potential</a></p>
        </div>
        <div className="anchors">
          <p><a href="#obstacles">Obstacles and Opportunities</a></p>
          <p><a href="#mob_movements">Current Mobilization Movements</a></p>
          <p><a href="#prayer">Prayer and Praises</a></p>
        </div>
        { profile.resource_link &&
          <p><a href={`${profile.resource_link}`} target="_blank" rel="noopener noreferrer"><span className="icon align icon_book"></span>Download {profile.resource_text}</a></p>
        }
        { profile.presentation_link &&
          <p><a href={`${profile.presentation_link}`} target="_blank" rel="noopener noreferrer"><span className="icon align icon_easel_alt"></span>Download {profile.presentation_text}</a></p>
        }
        { profile.other_resources_link &&
          <p><a href={`${profile.other_resources_link}`} target="_blank" rel="noopener noreferrer"><span className="icon align icon_documents"></span>{profile.other_resources_text}</a></p>
        }  
        <p className="to_top"><a href="#header"><span className="icon arrow_up_alt"></span>Back to Top</a></p>
        <div className="improve">
          <p>Help us improve our statistics! <a href="mailto:info@mobilization.org">Contribute data to the Index</a></p>
        </div>
      </div>
    </div>
  </aside>

const Banner = ({
  profile
}) => {
  const imgUrl = `${PATH_IMG_BASE}${profile.hero_image}`;
  const divStyle = {
      backgroundImage: 'url(' + imgUrl + ')'
  }
  return (
    <div className="cp_banner full_w" style={divStyle}></div>
  );
}

const FigureOptions = () =>
  <div className="figure_options flex">
    <div><span className="icon icon_camera flex"></span><span>Miguel M.</span></div>
    <div><span>Contribute a photo</span></div>
  </div>

const Figure = (
  props,
) =>
  props.filename &&
    <figure className="mob_fw">
      <img src={`${PATH_IMG_BASE}${props.filename}`} alt="overview" />
      <FigureOptions />
    </figure>

const SubIndex = ({
  id,
  title,
  rank,
  subtitle,
  analysis,
  image,
  pullquote,
  footnotes,
  children
}) => {
  const analysisClass = classNames(
    'dot',
    'large',
    { 'svg': children },
    { id: !children }
  ); 

  return (
    <div className="sub_index" id={id}>
      <div className="icon_text flex">
        <div className={analysisClass}>
          {children}
        </div>
        <div className="text">
          <h3>{title}</h3>
          { rank &&
            <h4>{rank}</h4>
          }
          { subtitle &&
            <h6>{subtitle}</h6>
          }  
        </div>
      </div>
      { analysis &&
        <RichContent prefix={id} content={analysis} footnotes={footnotes}></RichContent>
      }
      <Figure filename={image} />
      { pullquote && 
        <q>{pullquote}</q>
      }            
    </div>
  );
}

export const SubHeader = ({
  country,
  profiles
}) => {
  const [countryName, setCountryName] = React.useState(country.name);

  useEffect(() => {
    setCountryName(country.name);
  }, [country.name]);

  const handleChange = event => {
    setCountryName(event.target.value);
  };

  const renderOptions = () => {
    return profiles.map((item, i) => {
      return (
        <MenuItem key={i} value={item.country_name} component={Link} to={`/profile/${strip(item.country_name)}`}>
          <CountryItem countryName={item.country_name} countryCode={item.country_code} />
        </MenuItem>           
      );
    });
  }  

  return (
    <div className="sub_header full_w">
      <div className="container">
        <ul>
          <li className="country">
            <Select
              id="country-profile-select"
              value={countryName}
              onChange={handleChange}
            >
              { renderOptions() }
            </Select>
          </li>
          <li><a href="#">Regional Profile</a></li>
          <li><Link to="/compare">Sort + Compare</Link></li>
          <li><Link to="/map">Back to Map</Link></li>
        </ul>
      </div>
    </div>
  );
}

export const RichContent = ({
  prefix,
  content,
  footnotes
}) => {
  const [contentWithFootnotes, setContentWithFootnotes] = useState(null);
  
  useEffect(() => {
    // Parse content and convert [[x]] to spans for footnotes
    const splits = content.split(/\[\[|\]\]+/);
    const newArr = splits.map((v,i) => {
      return i % 2 === 1 && footnotes[v] ? "<span><input class='footnote' type='checkbox' id='"+prefix+i+"'/><label for='"+prefix+i+"'><span class='num'>"+v+"</span><span class='close'>×</span></label><span>"+footnotes[v].content+"</span></span>" : v;
    });

    setContentWithFootnotes(newArr.join(""));

  }, [prefix, content, footnotes]);
  
  return (
    contentWithFootnotes && 
      <Markdown
        options={{ forceBlock: true }}
      >
        {contentWithFootnotes}
      </Markdown>
  );
}

export const Profile = (
  props,
) => {
  const [profile, setProfile] = useState(null);
  const [country, setCountry] = useState(null);
  
  useEffect(() => {
    const { match: { params } } = props;

    const cn = strip(params.countryName);

    if(props.profiles && props.profiles.length) {
      const result = props.profiles.find(item => (strip(item.country_name) === cn));
      if(result) {
        console.log('-------------- PROFILE: '); console.log(result);
        setProfile(result);
      }
    } 
    if(props.countries && props.countries.length) {
      const result = props.countries.find(item => (strip(item.name) === cn));
      if(result) {
        console.log('-------------- COUNTRY: '); console.log(result);
        setCountry(result); 
      }  
    } 

  }, [props]);

  return (
    profile && country && props.footnotes ? 
    <React.Fragment>

      <SubHeader country={country} profiles={props.profiles} />

      <Banner profile={profile} />

      <Info country={country} profile={profile} />

      <RangeBox profile={profile} country={country} {...props} />
      
      {/* { console.log("props: ") }
      { console.log(props) }
      { console.log("props.profiles: ")}
      { console.log(props.profiles)}
      { console.log("profile: ")}      
      { console.log(profile)} */}




      <div className="top_content">
        <h1 className="huge_title_dark">{profile.overview_title}</h1>
        
        { profile.overview && 
          <RichContent prefix="overview" content={profile.overview} footnotes={props.footnotes}></RichContent>
        }

        { profile.overview_pullquote && 
          <q>{profile.overview_pullquote}</q>
        }

        <Figure filename={profile.overview_image} />
        
        
      </div>

      <Sidebar country={country} profile={profile} />

      <main id="main_content">

        <div className="narrow_content">
          
          <SubIndex 
            id="evangelicals"
            title="Number of Evangelicals"
            rank={country.number_of_evangelicals_rank}
            subtitle={profile.number_of_evangelicals_title}
            analysis={profile.number_of_evangelicals_analysis}
            image={profile.number_of_evangelicals_image}
            pullquote={profile.number_of_evangelicals_pullquote}
            footnotes={props.footnotes}
          />

          <SubIndex 
            id="bridges"
            title="Cultural Bridges to UPGs"
            rank={country.cultural_bridges_to_upgs_rank}
            subtitle={profile.cultural_bridges_title}
            analysis={profile.cultural_bridges_analysis}
            image={profile.cultural_bridges_image}
            pullquote={profile.cultural_bridges_pullquote}
            footnotes={props.footnotes}
          />

          <SubIndex 
            id="regional"
            title="Regional Access to UPGs"
            rank={country.regional_access_to_upgs_rank}
            subtitle={profile.strategic_access_title}
            analysis={profile.strategic_access_analysis}
            image={profile.strategic_access_image}
            pullquote={profile.strategic_access_pullquote}
            footnotes={props.footnotes}
          />

          <SubIndex 
            id="freedom"
            title="Religious Freedoms"
            rank={country.religious_freedom_rank}
            subtitle={profile.religious_freedoms_title}
            analysis={profile.religious_freedoms_analysis}
            image={profile.religious_freedoms_image}
            pullquote={profile.cultural_bridges_pullquote}
            footnotes={props.footnotes}
          />

          <SubIndex 
            id="prosperity"
            title="Prosperity"
            rank={country.prosperity_rank}
            subtitle={profile.prosperity_title}
            analysis={profile.prosperity_analysis}
            image={profile.prosperity_image}
            pullquote={profile.prosperity_pullquote}
            footnotes={props.footnotes}
          />          

          <SubIndex 
            id="sending"
            title="Current Sending Abroad"
            rank={country.current_sending_abroad_rank}
            subtitle={profile.current_sending_title}
            analysis={profile.current_sending_analysis}
            image={profile.current_sending_image}
            pullquote={profile.current_sending_pullquote}
            footnotes={props.footnotes}
          />   

          <SubIndex 
            id="mobilization"
            title="Mobilization Potential"
            rank={country.mobilization_potential}
            subtitle={profile.mobilization_potential_title}
            analysis={profile.mobilization_potential_analysis}
            image={profile.mobilization_potential_image}
            pullquote={profile.mobilization_potential_pullquote}
            footnotes={props.footnotes}
          />   

          <SubIndex 
            id="obstacles"
            title="Obstacles and Opportunities"
            subtitle={profile.obstacles_and_opportunities_title}
            analysis={profile.obstacles_and_opportunities_analysis}
            image={profile.obstacles_and_opportunities_image}
            pullquote={profile.obstacles_and_opportunities_pullquote}
            footnotes={props.footnotes}
          >
            <ObstaclesIcon />
          </SubIndex>

          <SubIndex 
            id="mob_movements"
            title="Current Mobilization Movements"
            subtitle={profile.current_mobilization_movements_title}
            analysis={profile.current_mobilization_movements_analysis}
            image={profile.current_mobilization_movements_image}
            pullquote={profile.current_mobilization_movements_pullquote}
            footnotes={props.footnotes}
          >
            <MovementsIcon />
          </SubIndex>

          <SubIndex 
            id="prayer"
            title="Prayer and Praises"
            subtitle={profile.prayer_and_praises_title}
            analysis={profile.prayer_and_praises_analysis}
            image={profile.prayer_and_praises_image}
            pullquote={profile.prayer_and_praises_pullquote}
            footnotes={props.footnotes}
          >
            <PrayerIcon />
          </SubIndex>

          <div className="vote flex">
            <div className="flex">
              <span className="icon">
                <IprayedIcon />
              </span>
              <span>I prayed for Brazil!</span>
            </div>
            <div className="votes flex">35</div>
          </div>

        </div>

      </main>

    </React.Fragment>
    :
    <div>
      <p>Loading. Please wait...</p>
    </div>
  );
}