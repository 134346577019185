import React, { useState } from 'react';
import './Table.css';
import classNames from 'classnames';
import { sortBy, some } from 'lodash';
import CheckIcon from '@material-ui/icons/Check';
import ToggleButton from '@material-ui/lab/ToggleButton';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import { Button } from '../../components/Button/Button'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
// import ArrowDownwardIcon from '@material-ui/icons/ArrowDownwardIcon';
import NumEvangelicals from '../../icons/NumEvangelicals.js';
import CulturalBridges from '../../icons/CulturalBridges.js';
import RegionalAccess from '../../icons/RegionalAccess.js';
import ReligiousFreedom from '../../icons/ReligiousFreedom.js';
import Prosperity from '../../icons/Prosperity.js';
import CurrentSending from '../../icons/CurrentSending.js';
import MobPotential from '../../icons/MobPotential.js';
import CustomMobPotential from '../../icons/CustomMobPotential.js';

const SORTS = {
  NONE: list => list,
  COUNTRY: list => sortBy(list, 'name'),
  NUM_EVANGELICALS: list => sortBy(list, 'number_of_evangelicals'),
  CULTURAL_BRIDGES: list => sortBy(list, 'cultural_bridges_to_upgs'),
  REGIONAL_ACCESS: list => sortBy(list, 'regional_access_to_upgs'),
  RELIGIOUS_FREEDOM: list => sortBy(list, 'religious_freedom'),
  PROSPERITY: list => sortBy(list, 'prosperity'),
  CURRENT_SENDING: list => sortBy(list, 'current_sending_abroad'),
  // MOB_POTENTIAL: list => sortBy(list, 'mobilization_potential'),

  NUM_EVANGELICALS_SCORE: list => sortBy(list, 'number_of_evangelicals_score'),
  CULTURAL_BRIDGES_SCORE: list => sortBy(list, 'cultural_bridges_to_upgs_score'),
  REGIONAL_ACCESS_SCORE: list => sortBy(list, 'regional_access_to_upgs_score'),
  RELIGIOUS_FREEDOM_SCORE: list => sortBy(list, 'religious_freedom_score'),
  PROSPERITY_SCORE: list => sortBy(list, 'prosperity_score'),
  CURRENT_SENDING_SCORE: list => sortBy(list, 'current_sending_abroad_score'),
  MOB_POTENTIAL_SCORE: list => sortBy(list, 'mobilization_potential_score'),
  CUSTOM_MOB_POTENTIAL_SCORE: list => sortBy(list, 'custom_mobilization_potential_score'),
};

function isFiltered(filterTerms) {
  return function(item) { 
    const allFalse = !some(filterTerms);
    if (allFalse) {
      return true;
    } else {
      for (var key in filterTerms) {
        //console.log("item.region_code: " + item.region_code + " key " + key + " has value " + filterTerms[key]);
        if(item.region_code === Number(key)) {
          return filterTerms[key];
        }  
      }
    }
  }
}

const Sort = ({ 
  sortKey,
  activeSortKey,
  activeSortIsReverse, 
  onSort, 
  children,
  isCustomWeights
}) => {
  const sortClass = classNames(
    'button-inline',
    { 'button-active': sortKey === activeSortKey },
    { 'arrow-down' : !activeSortIsReverse && sortKey === activeSortKey },
    { 'arrow-up' : activeSortIsReverse && sortKey === activeSortKey },
    { 'disabled' : !isCustomWeights && sortKey === 'CUSTOM_MOB_POTENTIAL_SCORE' }
  );  

  return (
    <Button 
      onClick={() => onSort(sortKey)}
      className={sortClass}
    >
      {children}
    </Button>
  );
}

const Weight = ({ 
  onWeight,
  name,
  value 
}) => {
  return (
    <Select
      value={value}
      onChange={onWeight}
      inputProps={{
        name,
        id: name+"-weight",
      }}
    >
      <MenuItem value={-1}>-1x</MenuItem>      
      <MenuItem value={-0.5}>-0.5x</MenuItem>      
      <MenuItem value={0}>0x</MenuItem>      
      <MenuItem value={0.5}>0.5x</MenuItem>      
      <MenuItem value={1}>1x</MenuItem>
      <MenuItem value={1.5}>1.5x</MenuItem>
      <MenuItem value={2}>2x</MenuItem>
      <MenuItem value={2.5}>2.5x</MenuItem>
      <MenuItem value={3}>3x</MenuItem>            
    </Select>
  );
}

const TableRow = ({
  item,
  isScores,
  onPin,
  isPinned,
  isCustomWeights
}) => {

  // const numberFormat = (value) =>
  //   new Intl.NumberFormat({
  //     style: 'percent'
  // }).format(value);

  const numberFormat = (value) =>
    value *100


  return (
    <div key={item.id} className="table-row">
      <span style={{ width: '25%'}}>

        <ToggleButton
          value="check"
          selected={isPinned}
          onChange={() => onPin(item.id)}
        >
          <CheckIcon />
        </ToggleButton>

        <span>{item.name}</span>
      </span>
      
      <span style={{ width: '10%'}}>{isScores === "scores" ? item.number_of_evangelicals_score.toFixed(1) : item.number_of_evangelicals.toLocaleString()}</span>
      <span style={{ width: '10%'}}>{isScores === "scores" ? item.cultural_bridges_to_upgs_score.toFixed(1) : item.cultural_bridges_to_upgs}</span>
      <span style={{ width: '10%'}}>{isScores === "scores" ? item.regional_access_to_upgs_score.toFixed(1) : item.regional_access_to_upgs.toLocaleString()}</span>
      <span style={{ width: '10%'}}>{isScores === "scores" ? item.religious_freedom_score.toFixed(1) : item.religious_freedom}</span>
      <span style={{ width: '10%'}}>{isScores === "scores" ? item.prosperity_score.toFixed(1) : item.prosperity}</span>
      {/* <span style={{ width: '10%'}}>{isScores === "scores" ? item.current_sending_abroad_score.toFixed(1) : item.current_sending_abroad}</span> */}
      <span style={{ width: '10%'}}>{isScores === "scores" ? item.current_sending_abroad_score.toFixed(1) : numberFormat(item.current_sending_abroad).toFixed(3)+`%`}</span>
      {/* <span style={{ width: '10%'}}>{isScores === "scores" ? item.current_sending_abroad_score.toFixed(1) : numberFormat(item.current_sending_abroad).toFixed(3)+`%`}</span> */}
      <span style={{ width: '10%'}}>{isScores === "scores" ? item.mobilization_potential_score.toFixed(1) : item.mobilization_potential}</span>
      {isCustomWeights ?
        <span style={{ width: '10%'}}>{isScores === "scores" ? item.custom_mobilization_potential_score.toFixed(1) : item.custom_mobilization_potential}</span> 
      : <span style={{ width: '10%'}}></span> }
    </div>
  );
};

export const Table = ({
  list, 
  pinnedList,
  regionFilters,
  isScores,
  onPin
}) => {

  const [sortKey, setSortKey] = useState('MOB_POTENTIAL_SCORE');
  const [isSortReverse, setIsSortReverse] = useState(true);
  const [weights, setWeights] = useState({
    NUM_EVANGELICALS: 1,
    CULTURAL_BRIDGES: 1,
    REGIONAL_ACCESS: 1,
    RELIGIOUS_FREEDOM: 1,
    PROSPERITY: 1,
    CURRENT_SENDING: 1
  });

  const onSort = (newKey, skipReverse=false) => {
    if(!skipReverse) {
      const isReverse = sortKey === newKey && !isSortReverse; // if sortKey in state is same as sortKey and reverse state is false
      setIsSortReverse(isReverse);
    }
    setSortKey(newKey);
  }

  const computeWeight = (thelist) => {
    const customList = thelist.map(obj=> ({
      ...obj, 
      custom_mobilization_potential_score: 
        (obj['number_of_evangelicals_score']*weights.NUM_EVANGELICALS +
        obj['cultural_bridges_to_upgs_score']*weights.CULTURAL_BRIDGES +
        obj['regional_access_to_upgs_score']*weights.REGIONAL_ACCESS +
        obj['religious_freedom_score']*weights.RELIGIOUS_FREEDOM +
        obj['prosperity_score']*weights.PROSPERITY +
        obj['current_sending_abroad_score']*weights.CURRENT_SENDING)/6
    }));

    const weightedList = customList.map(obj=> ({
      ...obj, 
      custom_mobilization_potential: 
        obj['custom_mobilization_potential_score'] <= 20 ? "Low" :
        obj['custom_mobilization_potential_score'] <= 30 ? "Moderate" :
        obj['custom_mobilization_potential_score'] <= 40 ? "High" :
        "Very High"
    }));

    return weightedList;
  };

  const onWeight = event => {
    setWeights(oldValues => ({
      ...oldValues,
      [event.target.name]: event.target.value,
    }));

    onSort(sortKey, true);
  };  

  const weightedList = computeWeight(list);
  const weightedPinnedList = computeWeight(pinnedList);

  // Check if all weights are 1
  const isCustomWeights = !Object.keys(weights).every((k) => weights[k] === 1);
  //console.log(isCustomWeights);

  //console.log(weightedList);

  const sortedList = SORTS[sortKey](weightedList);
  const reverseSortedList = isSortReverse
    ? sortedList.reverse()
    : sortedList;

  return(
    <div className="table">
      <div className="table-header">
        <span style={{ width: '25%' }}>
          <Sort 
            sortKey={'COUNTRY'} 
            onSort={onSort} 
            activeSortKey={sortKey}
            activeSortIsReverse={isSortReverse}
          >
            <ArrowDropDownIcon />
            COUNTRY
          </Sort>
        </span>
        <span style={{ width: '10%' }}>
          <Sort 
            sortKey={isScores === "scores" ? 'NUM_EVANGELICALS_SCORE' : 'NUM_EVANGELICALS'}    
            onSort={onSort} 
            activeSortKey={sortKey}
            activeSortIsReverse={isSortReverse}
          >
            <ArrowDropDownIcon />
            <NumEvangelicals />
          </Sort>
        </span>
        <span style={{ width: '10%' }}>
          <Sort 
            sortKey={isScores === "scores" ? 'CULTURAL_BRIDGES_SCORE' : 'CULTURAL_BRIDGES'}    
            onSort={onSort} 
            activeSortKey={sortKey}
            activeSortIsReverse={isSortReverse}
          >
            <ArrowDropDownIcon />
            <CulturalBridges />
          </Sort>
        </span>
        <span style={{ width: '10%' }}>
          <Sort 
            sortKey={isScores === "scores" ? 'REGIONAL_ACCESS_SCORE' : 'REGIONAL_ACCESS'}    
            onSort={onSort} 
            activeSortKey={sortKey}
            activeSortIsReverse={isSortReverse}
          >
            <ArrowDropDownIcon />
            <RegionalAccess />
          </Sort>
        </span>
        <span style={{ width: '10%' }}>
          <Sort 
            sortKey={isScores === "scores" ? 'RELIGIOUS_FREEDOM_SCORE' : 'RELIGIOUS_FREEDOM'}    
            onSort={onSort} 
            activeSortKey={sortKey}
            activeSortIsReverse={isSortReverse}
          >
            <ArrowDropDownIcon />
            <ReligiousFreedom />
          </Sort>
        </span>
        <span style={{ width: '10%' }}>
          <Sort 
            sortKey={isScores === "scores" ? 'PROSPERITY_SCORE' : 'PROSPERITY'}    
            onSort={onSort} 
            activeSortKey={sortKey}
            activeSortIsReverse={isSortReverse}
          >
            <ArrowDropDownIcon />
            <Prosperity />
          </Sort>
        </span>                    
        <span style={{ width: '10%' }}>
          <Sort 
            sortKey={isScores === "scores" ? 'CURRENT_SENDING_SCORE' : 'CURRENT_SENDING'}    
            onSort={onSort} 
            activeSortKey={sortKey}
            activeSortIsReverse={isSortReverse}
          >
            <ArrowDropDownIcon />
            <CurrentSending />
          </Sort>
        </span>                    
        <span style={{ width: '10%' }}>
          <Sort 
            sortKey={'MOB_POTENTIAL_SCORE'} 
            onSort={onSort} 
            activeSortKey={sortKey}
            activeSortIsReverse={isSortReverse}
          >
            <ArrowDropDownIcon />
            <MobPotential />
          </Sort>
        </span>
        <span style={{ width: '10%' }}>
          <Sort 
            sortKey={'CUSTOM_MOB_POTENTIAL_SCORE'} 
            onSort={onSort} 
            activeSortKey={sortKey}
            activeSortIsReverse={isSortReverse}
            isCustomWeights={isCustomWeights}
          >
            <ArrowDropDownIcon />
            <CustomMobPotential />
          </Sort>
        </span>                              
      </div>
      
      <div className="table-header hide_mob">
        <span style={{ width: '25%' }}>Adjust Sub-Index Weighting</span>
        <span style={{ width: '10%' }}>
          <Weight 
            onWeight={onWeight}
            name="NUM_EVANGELICALS"
            value={weights.NUM_EVANGELICALS}
          ></Weight>
        </span>
        <span style={{ width: '10%' }}>
          <Weight 
            onWeight={onWeight}
            name="CULTURAL_BRIDGES"
            value={weights.CULTURAL_BRIDGES}
          ></Weight>
        </span>
        <span style={{ width: '10%' }}>
          <Weight 
            onWeight={onWeight}
            name="REGIONAL_ACCESS"
            value={weights.REGIONAL_ACCESS}
          ></Weight>
        </span>
        <span style={{ width: '10%' }}>
          <Weight 
            onWeight={onWeight}
            name="RELIGIOUS_FREEDOM"
            value={weights.RELIGIOUS_FREEDOM}
          ></Weight>
        </span>                        
        <span style={{ width: '10%' }}>
          <Weight 
            onWeight={onWeight}
            name="PROSPERITY"
            value={weights.PROSPERITY}
          ></Weight>
        </span>
        <span style={{ width: '10%' }}>
          <Weight 
            onWeight={onWeight}
            name="CURRENT_SENDING"
            value={weights.CURRENT_SENDING}
          ></Weight>
        </span>                
        <span style={{ width: '10%' }}></span>
        <span style={{ width: '10%' }}></span>                
      </div>

      {weightedPinnedList && weightedPinnedList.map( item =>
        <TableRow 
          key={item.id}          
          item={item}
          isScores={isScores}
          onPin={onPin}
          isPinned={true}
          isCustomWeights={isCustomWeights}
        ></TableRow>
      )}        
      {reverseSortedList.filter(isFiltered(regionFilters)).map( item =>
        <TableRow 
          key={item.id}
          item={item}
          isScores={isScores}
          onPin={onPin}
          isPinned={false}
          isCustomWeights={isCustomWeights}
        />
      )}
    </div>
  );
}

