import React from 'react';
import Flag from 'react-world-flags';
import './CountryItem.css';

export const CountryItem = ({ 
    countryName, 
    countryCode
}) =>
<div className="country_item flex">
  <span className="country_flag">
    <Flag code={countryCode} width="28px" height="20px" />
    {/* <CountryFlag width="28px" height="20px" /> */}
  </span>
  <span className="country_name">{countryName}</span>  
</div>
