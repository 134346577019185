import React from "react";

const SvgCulturalBridges = props => (
  <svg width={48} height={48} {...props}>
    <defs>
      <circle id="cultural-bridges_svg__a" cx={24} cy={24} r={24} />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="cultural-bridges_svg__b" fill="#fff">
        <use xlinkHref="#cultural-bridges_svg__a" />
      </mask>
      <use
        fill="#D8D8D8"
        fillRule="nonzero"
        xlinkHref="#cultural-bridges_svg__a"
      />
      <g mask="url(#cultural-bridges_svg__b)" fill="#4C2F39" fillRule="nonzero">
        <path d="M-60-22h160v80H-60z" />
      </g>
      <path
        d="M8 29a1 1 0 010-2h32a1 1 0 010 2H8z"
        fill="#FFF"
        fillRule="nonzero"
        mask="url(#cultural-bridges_svg__b)"
      />
      <path
        d="M16 13a1 1 0 012 0v14.757a1 1 0 01-2 0V13zm0 18.757a1 1 0 112 0v2.758a1 1 0 01-2 0v-2.758zM30 13a1 1 0 012 0v14.757a1 1 0 01-2 0V13zm0 18.757a1 1 0 112 0v2.758a1 1 0 01-2 0v-2.758z"
        fill="#FFF"
        fillRule="nonzero"
        mask="url(#cultural-bridges_svg__b)"
      />
      <path
        d="M17.01 17.62C15.069 22.787 10.717 29 8 29a1 1 0 010-2c2.2 0 8-9.75 8-13.757 0-1.334 2-1.334 2 0 0 4.039 2.605 6.53 6 6.53s6-2.491 6-6.53c0-1.334 2-1.334 2 0 0 4.365 1.179 7.951 3.167 10.533C36.731 25.806 38.692 27 40 27a1 1 0 010 2c-3.844 0-7.943-4.326-9.424-10.674-1.434 2.177-3.824 3.447-6.576 3.447-3.037 0-5.633-1.547-6.99-4.153z"
        fill="#FFF"
        fillRule="nonzero"
        mask="url(#cultural-bridges_svg__b)"
      />
      <path
        fill="#FFF"
        fillRule="nonzero"
        mask="url(#cultural-bridges_svg__b)"
        d="M22.5 21h2v7h-2z"
      />
    </g>
  </svg>
);

export default SvgCulturalBridges;
