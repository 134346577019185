import React from "react";

const SvgIprayedIcon = props => (
  <svg width={24} height={30} {...props}>
    <g
      fillRule="nonzero"
      stroke="#8C9B94"
      strokeWidth={1.039}
      fill="none"
      strokeLinecap="round"
    >
      <path d="M15.002 9.302L5.296 1.045C3.7 1.045-.01 3.64 1.891 10.984c.373 1.438 1.982 3.419 4.827 5.943 1.126 3.065 2.354 5.02 3.686 5.862 1.997 1.264 5.634 3.068 7.687 5.233 2.728-.482 4.487-2.227 5.277-5.233l-1.185-1.264c-.5-2.915-1.406-7.073-3.148-9.373-1.743-2.301-2.89-6.172-4.033-8.512-1.323 0-2.496.321-3.032 2.764M13.27 28.013c-.861 1.063-4.94 2.165-7.452.65 0-2.25-.415-4.424-2.084-5.883-2.187-1.91-3.062-4.577-2.625-8.001M2.445 3.272l10.116 8.145M1.361 6.984l8.671 7.053" />
    </g>
  </svg>
);

export default SvgIprayedIcon;
