import React, { useState, useEffect } from 'react';
import './Compare.css';
import { Table } from '../Table/Table'
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';

import sortImage from '../../images/sort-illustration.png';
import FilterIcon from '../../icons/FilterIcon.js';

export const Compare = (
  props,
) => {
  const [countries, setCountries] = useState(null);
  const [pinnedList, setPinnedList] = useState([]);
  const [isScores, setIsScores] = useState("values");
  const [filterAnchor, setFilterAnchor] = useState(null);
  const [regionFilters, setRegionFilters] = useState({
    7: false,
    6: false,
    8: false,
    11: false,
    12: false,
    5: false,
    3: false,
    4: false,
    2: false,
    1: false,
    9: false,
    10: false
  });

  useEffect(() => {
    if(props.countries) {
      setCountries(props.countries); 
    } 
  }, [props.countries]);


  const onPin = (id) => {
    const isNotId = item => item.id !== id;
    const isId = item => item.id === id;

    const updatedResults = countries.filter(isNotId);
    const pinHits = countries.filter(isId);

    if (pinHits && pinHits.length) {
      setCountries(updatedResults);
      setPinnedList(pinnedList.concat(pinHits));
      // console.log("Pinned: "+pinHits[0].name);
    } else {
      const updatedPins = pinnedList.filter(isNotId);
      const unpinHits = pinnedList.filter(isId);

      setPinnedList(updatedPins);
      setCountries(countries.concat(unpinHits));
      // console.log("UN-Pinned: "+unpinHits[0].name);
    }
  }

  const onScoreValueToggle = (event, newValue) => {
    if(newValue)
      setIsScores(newValue);

    //this.setState({ isScores: !this.state.isScores ? "scores" : "values" });
  }

  const onFilterOpen = event => {
    setFilterAnchor(event.currentTarget);
  };

  const onFilterClose = () => {
    setFilterAnchor(null);
  };

  const onFilterChange = name => event => {
    setRegionFilters({ ...regionFilters, [name]: event.target.checked });
  };

  // console.log(regionFilters);

  return(
    <React.Fragment>

      <div className="page_title full_w">
        <div className="container">
          <h1>Sort + Compare</h1>
        </div>
      </div>


      <main id="main_content">
        <div className="sort_compare_section two_col_section img_right">
          <div className="container">
            <div className="left">
              <figure className="flex">
                <img src={sortImage} alt="Sort Illustration" />
              </figure>
            </div>
            <div className="right">
              <p>The Mobilization Index exists to help you identify locations that are ripe for mobilization efforts. Below we have made available the data to allow you sort and compare different locations based on the criteria that best suit your organization or group’s strengths. For instance, if you excel at mobilizing children and families, you can increase the weight of the percentage of the population under age 15. May God guide you as you plan and strategize!</p>
              <p><a href="#">Read more about the Sub-Indices</a></p>
            </div>
          </div>
        </div>
        
        <div className="interacions hide_mob">
          <div className="left flex">
            
            <Button aria-controls="filter-regions" aria-haspopup="true" onClick={onFilterOpen}>
              <FilterIcon />
              <span className="filter-label">Filter Regions</span>
            </Button>
            <Menu
              id="region-filter-menu"
              anchorEl={filterAnchor}
              keepMounted
              open={Boolean(filterAnchor)}
              onClose={onFilterClose}
            >
              <form>
                <FormControl component="fieldset">
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox onChange={onFilterChange('7')} value="7" />}
                      label="East and Southern Africa"
                    />
                    <FormControlLabel
                      control={<Checkbox onChange={onFilterChange('6')} value="6" />}
                      label="North and Middle East Africa"
                    />
                    <FormControlLabel
                      control={<Checkbox onChange={onFilterChange('8')} value="8" />}
                      label="West and Central Africa"
                    />
                    <FormControlLabel
                      control={<Checkbox onChange={onFilterChange('11')} value="11" />}
                      label="Latin America"
                    />  
                    <FormControlLabel
                      control={<Checkbox onChange={onFilterChange('12')} value="12" />}
                      label="North America and Caribbean"
                    />  
                    <FormControlLabel
                      control={<Checkbox onChange={onFilterChange('5')} value="5" />}
                      label="Central Asia"
                    />  
                    <FormControlLabel
                      control={<Checkbox onChange={onFilterChange('3')} value="3" />}
                      label="Northeast Asia"
                    />  
                    <FormControlLabel
                      control={<Checkbox onChange={onFilterChange('4')} value="4" />}
                      label="South Asia"
                    />  
                    <FormControlLabel
                      control={<Checkbox onChange={onFilterChange('2')} value="2" />}
                      label="Southeast Asia"
                    />  
                    <FormControlLabel
                      control={<Checkbox onChange={onFilterChange('1')} value="1" />}
                      label="Australia and Pacific"
                    />  
                    <FormControlLabel
                      control={<Checkbox onChange={onFilterChange('9')} value="9" />}
                      label="Eastern Europe and Eurasia"
                    />  
                    <FormControlLabel
                      control={<Checkbox onChange={onFilterChange('10')} value="10" />}
                      label="Western Europe"
                    />  
                  </FormGroup>

                  <Button 
                    // type="submit"
                    variant="outlined"
                    onClick={onFilterClose}
                  >
                    Apply
                  </Button>
                </FormControl>
              </form>
            </Menu>
            <ToggleButtonGroup
              value={isScores}
              exclusive
              onChange={onScoreValueToggle}
              aria-label="values scores toggle"
            >
              <ToggleButton value="values" aria-label="values">
                Values
              </ToggleButton>
              <ToggleButton value="scores" aria-label="scores">
                Scores
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
          <div className="right flex">
            <span style={{ width: '12.5%' }}>Total Number of Evangelicals</span>
            <span style={{ width: '12.5%' }}>Cultural Bridges to UPGs</span>
            <span style={{ width: '12.5%' }}>Regional Access to UPGs</span>
            <span style={{ width: '12.5%' }}>Religious Freedom</span>
            <span style={{ width: '12.5%' }}>Prosperity</span>
            <span style={{ width: '12.5%' }}>Current Sending Abroad</span>
            <span style={{ width: '12.5%' }}>Mobilization Potential</span>
            <span style={{ width: '12.5%' }}>Custom Mobilization Potential</span>
          </div>          
        </div>

        { countries &&
          <Table
            list={countries}
            pinnedList={pinnedList}
            regionFilters={regionFilters}
            isScores={isScores}
            onPin={onPin}
          />
        }

      </main>

    </React.Fragment>
  );
}      