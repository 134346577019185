import React from 'react';
import './Header.css';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Button } from '../../Button/Button'
import CMMLogo from '../../../icons/CmmLogo.js';

export const Header = ({ 
  onDrawerToggle,
  isDrawerOpen
}) => {

  const drawerClass = classNames(
    { 'open': isDrawerOpen }
  );    

  return (
    <header id="header">
      <div className="main_header full_w">
        <div className="container">
            <div className="header_menu_button flex">
              {/* <Button onClick={toggleDrawer('left', true)}>Open Left</Button> */}
              <Button 
                onClick={onDrawerToggle(true)}
                className="toggleButton"
              >
                <div id="main-menu-toggle" className={drawerClass}>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </Button>
              <span id="main-menu-label" className="mobile_hidden">{ isDrawerOpen ? "Close" : "Menu" }</span>
            </div>
            
            <Link id="logo" to="/">
              <span>Mobilization Index</span>
              <p className="mobile_hidden">Vizualizing the sending potential of the global church</p>
            </Link>

            <div className="a_project_of flex">
              <a href="http://mobilization.org" target="_blank" rel="noopener noreferrer" className="a_project_of flex tablet_hidden mobile_hidden">
                <span>A Project Of</span>
                <CMMLogo width="24px" height="24px" />
              </a>
            </div>
        </div>
      </div>
    </header>
  );
}


