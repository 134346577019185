import React from "react";

const SvgCmmLogo = props => (
  <svg width={24} height={24} {...props}>
    <path
      d="M11.981 0l-4.53 4.533h2.51l.002 2.133h.075l-.075.075v.002l2.06-1.804 1.905 1.734h.077v-2.14h2.51L11.982 0zM9.93 8.455h1.14v2.588h-2.49l.002-1.145-2.066 2.067 2.064 2.063v-1.141h2.49v2.49H9.925l2.064 2.063 2.068-2.067h-1.146v-2.487h2.653l.003 1.14 2.061-2.06L15.565 9.9l-.002 1.143h-2.65V8.454l1.144.003-2.067-2.066L9.93 8.455zM6.665 6.666l.001 3.298H4.532l.001-2.51L0 11.989l4.533 4.528-.001-2.509h2.13l.001 3.3h3.3l-.003 2.129-2.509.003 4.53 4.533 4.535-4.533h-2.51V17.3H17.3v-3.293h2.137v2.51l4.533-4.532-4.533-4.53v2.51h-2.14V6.672h-3.292v.07l-.077-.07h-.001l.93.933 1.507 1.503.933.93v-.002l1.844 1.908-1.841 1.984v-.003l-.935.936-1.503 1.508-.886.885-1.953 1.846-2.06-1.873v.01l-.862-.865-1.503-1.503-.86-.863-2.023-2.177L6.74 9.964h-.006l.862-.861 1.507-1.504.86-.858v-.075H6.665z"
      fill="#FEC10E"
      fillRule="evenodd"
    />
  </svg>
);

export default SvgCmmLogo;
