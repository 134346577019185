import React, { useState, useEffect } from 'react';
import './Nav.css';
import { Link } from 'react-router-dom';
import {CountrySelect} from './CountrySelect'

import { 
  strip
} from '../../../constants';

export const Nav = ({
  profiles, 
  onDrawerToggle, 
  onProfileChange
}) => {
  const [profileItems, setProfileItems] = useState([]);

  useEffect(() => {

    profiles &&
    setProfileItems( profiles.map((item, i) => {
      console.log('test');
      return { key:i, label: item.country_name, country_code: item.country_code, value: `/profile/${strip(item.country_name)}` }
    }));

  }, [profiles]);

  return(
    <nav className="nav_block">

      {/* <Autocomplete options={profileItems} onDrawerToggle={onDrawerToggle} onProfileChange={onProfileChange} />  */}
      <CountrySelect options={profileItems} onDrawerToggle={onDrawerToggle} onProfileChange={onProfileChange} /> 
    
      <ul className="menu_list default" onClick={onDrawerToggle}>
        <li><Link to="/map">Map</Link></li>
        <li><Link to="/compare">Sort + Compare</Link></li>
      </ul>
      <ul className="menu_list small" onClick={onDrawerToggle}>
        <li><Link to="/about">About</Link></li>
        <li><Link to="/sources">Sources</Link></li>
        <li><Link to="/definitions">Definitions</Link></li>
        <li><Link to="/contact">Contact</Link></li>
      </ul>
    </nav>
  );
}