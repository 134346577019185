import React from "react";

const SvgMobPotential = props => (
  <svg width={48} height={48} {...props}>
    <defs>
      <circle id="mob-potential_svg__a" cx={24} cy={24} r={24} />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="mob-potential_svg__b" fill="#fff">
        <use xlinkHref="#mob-potential_svg__a" />
      </mask>
      <use
        fill="#D8D8D8"
        fillRule="nonzero"
        xlinkHref="#mob-potential_svg__a"
      />
      <g mask="url(#mob-potential_svg__b)" fill="#FFCA04" fillRule="nonzero">
        <path d="M-60-22h160v80H-60z" />
      </g>
      <path
        d="M23.99 29a1 1 0 011 1v6.585l1.294-1.292a1 1 0 111.414 1.414l-3 3a1.006 1.006 0 01-.09.08l.09-.08a1.007 1.007 0 01-.707.293h-.033l-.053-.004.086.004a1.008 1.008 0 01-.622-.216 1.02 1.02 0 01-.085-.077l-3-3a1 1 0 011.414-1.414l1.292 1.292V30a1 1 0 011-1zm5.697-2.866l5.703 3.292-.473-1.765a1 1 0 111.932-.518l1.098 4.098a1.003 1.003 0 01-.535 1.162.996.996 0 01-.172.063l-4.098 1.098a1 1 0 11-.518-1.932l1.765-.473-5.702-3.293a1 1 0 111-1.732zM19.66 26.5a1 1 0 01-.367 1.366l-5.703 3.293 1.766.473a1 1 0 01-.517 1.932l-4.099-1.098a1 1 0 01-.607-.466l-.04-.078a.999.999 0 01-.06-.68l1.098-4.099a1 1 0 011.932.518l-.474 1.765 5.704-3.292a1 1 0 011.367.366zm13.48-12.064l4.099 1.098a1.006 1.006 0 01.475.286 1.005 1.005 0 01.232.939l-1.098 4.098a1 1 0 11-1.932-.518l.473-1.766-5.703 3.293a1 1 0 11-1-1.732l5.702-3.294-1.765-.472a1 1 0 11.518-1.932zm-17.077.707a1 1 0 01-.707 1.225l-1.766.472 5.703 3.294a1 1 0 11-1 1.732l-5.704-3.293.474 1.766a1 1 0 11-1.932.518l-1.098-4.098a1 1 0 01.16-.852l-.06.093a1.006 1.006 0 01.607-.466l4.099-1.098a1 1 0 011.224.707zM23.991 8h.019c.023 0 .046.002.07.004L23.99 8a1.008 1.008 0 01.618.213l.008.007.082.073 3 3a1 1 0 01-1.414 1.414l-1.294-1.293V18a1 1 0 01-2 0v-6.586l-1.292 1.293a1 1 0 11-1.414-1.414l3-3 .081-.073.004-.003-.085.076A1.007 1.007 0 0123.99 8z"
        fill="#FFF"
        fillRule="nonzero"
        mask="url(#mob-potential_svg__b)"
      />
    </g>
  </svg>
);

export default SvgMobPotential;
