import React from 'react';
import './Button.css';

// Conditional rendering to show loading message. Higher-order component.
const withLoading = (Component) => ({ isLoading, ...rest }) => 
  isLoading
    ? <Loading />
    : <Component { ...rest } />

const Loading = () =>
  <div>Loading...</div>

export const Button = ({ onClick, className = '', children }) =>
  <button
    onClick={onClick}
    className={className}
    type="button"
  >
    {children}
  </button>  

export const ButtonWithLoading = withLoading(Button);