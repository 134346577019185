import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import './CountrySelect.css';

import {CountryItem} from './CountryItem';

export const CountrySelect = (
  { 
    options, 
    onDrawerToggle, 
    onProfileChange 
  }
  
) => {
  const [value, setValue] = useState(null);

  const handleChange = (event, value) => {
    console.log(value);
    setValue(value);
    onProfileChange(value);
    onDrawerToggle && onDrawerToggle(false);
    // history && history.push('/compare');
  }

  return (
    <Autocomplete
      id="country-profile-search"
      options={options}
      value={value}
      onChange={handleChange}
      
      renderOption={(option) => (
        <CountryItem countryName={option.label} countryCode={option.country_code} />
      )}

      renderInput={params => (
        <TextField 
          {...params} 
          // variant="outlined"
          placeholder="Search for a country or region"
          // label="Search for a country or region" 
          fullWidth
          InputLabelProps={{ shrink: false }} 
        />
      )}
    />
  );
}

