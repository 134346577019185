import React from "react";

const SvgCurrentSending = props => (
  <svg width={48} height={48} {...props}>
    <defs>
      <circle id="current-sending_svg__a" cx={24} cy={24} r={24} />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="current-sending_svg__b" fill="#fff">
        <use xlinkHref="#current-sending_svg__a" />
      </mask>
      <use
        fill="#D8D8D8"
        fillRule="nonzero"
        xlinkHref="#current-sending_svg__a"
      />
      <g mask="url(#current-sending_svg__b)" fill="#85843D" fillRule="nonzero">
        <path d="M-60-22h160v80H-60z" />
      </g>
      <path
        d="M33.898 18.572L13.78 27.717a1 1 0 01-.861-.016l-3.211-1.606-.369.185 2.616 3.924 11.176-3.44a1 1 0 011.29 1.047l-.74 8.138c.282-.195.526-.387.73-.575l4.493-10.781a1 1 0 01.509-.526c5.6-2.546 8.876-4.542 9.399-5.308-.794-.899-3.03-1.056-4.914-.187zm-5.857.465l5.024-2.284c2.907-1.341 6.559-.937 7.715 1.376a1 1 0 01.106.447c0 1.83-3.251 3.93-10.287 7.148l-4.422 10.612a1 1 0 01-.216.322c-.696.696-1.695 1.363-3.003 2.016a1 1 0 01-1.443-.985l.78-8.574-10.464 3.22a1 1 0 01-1.126-.402l-3.658-5.487a1 1 0 01.385-1.449l1.829-.914a1 1 0 01.894 0l3.231 1.615 14.655-6.66z"
        fill="#FFF"
        fillRule="nonzero"
        mask="url(#current-sending_svg__b)"
      />
      <path
        d="M18.6 20.2a1 1 0 01-1.2 1.6l-4-3a1 1 0 01-.107-1.507C14.168 16.418 15.422 16 17 16a1 1 0 01.316.051l6 2a1 1 0 11-.632 1.898l-5.841-1.947c-.381.009-.718.05-1.013.12L18.6 20.2z"
        fill="#FFF"
        fillRule="nonzero"
        mask="url(#current-sending_svg__b)"
      />
    </g>
  </svg>
);

export default SvgCurrentSending;
