import React from 'react';
import './Footer.css';
import { Nav } from '../Nav/Nav';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter } from '@fortawesome/free-brands-svg-icons';


export const Footer = ({
  profiles, 
  onDrawerToggle, 
  onProfileChange
}) => {
  return(
    <footer id="footer">
      <div className="decore_line_1 full_w"></div>
      <div className="main_footer light full_w">
        <div className="container">

          <div className="left">
            <div className="footer_logo">
              <Link to="/">Mobilization Index</Link>
              <p className="small">Vizualizing the sending potential of the global church</p>
            </div>
            <p className="small">A project of the <a href="https://www.mobilization.org/" target="_blank" rel="noopener noreferrer">Center for Mission Mobilization</a></p>
            <ul className="social flex">
              <li>
                <a href="https://www.facebook.com/missionmobilization" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faFacebookF} fixedWidth />
                </a>
              </li>
              <li>
                <a href="https://twitter.com/CMMobilization" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faTwitter} fixedWidth />
                </a>
              </li>
            </ul>
          </div>

          <div className="right">
            <Nav profiles={profiles} onProfileChange={onProfileChange} />
          </div>

        </div>
      </div>
    </footer>
  );
}