import React from "react";

const SvgProsperity = props => (
  <svg width={48} height={48} {...props}>
    <defs>
      <circle id="prosperity_svg__a" cx={24} cy={24} r={24} />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="prosperity_svg__b" fill="#fff">
        <use xlinkHref="#prosperity_svg__a" />
      </mask>
      <use fill="#D8D8D8" fillRule="nonzero" xlinkHref="#prosperity_svg__a" />
      <g mask="url(#prosperity_svg__b)" fill="#3E4D3F" fillRule="nonzero">
        <path d="M-60-22h160v80H-60z" />
      </g>
      <path
        d="M26 35c-7.18 0-13-5.82-13-13S18.82 9 26 9s13 5.82 13 13-5.82 13-13 13zm0-2c6.075 0 11-4.925 11-11s-4.925-11-11-11-11 4.925-11 11 4.925 11 11 11z"
        fill="#FFF"
        fillRule="nonzero"
        mask="url(#prosperity_svg__b)"
      />
      <path
        d="M21 42c-8.837 0-16-7.163-16-16s7.163-16 16-16 16 7.163 16 16-7.163 16-16 16z"
        fill="#3E4D3F"
        fillRule="nonzero"
        mask="url(#prosperity_svg__b)"
      />
      <path
        d="M21 39c-7.18 0-13-5.82-13-13s5.82-13 13-13 13 5.82 13 13-5.82 13-13 13zm0-2c6.075 0 11-4.925 11-11s-4.925-11-11-11-11 4.925-11 11 4.925 11 11 11z"
        fill="#FFF"
        fillRule="nonzero"
        mask="url(#prosperity_svg__b)"
      />
      <path
        d="M26.949 31.684a1 1 0 11-1.898.632l-1-3a1 1 0 01.055-.763C24.696 27.37 25 25.855 25 24c0-3.125-1.167-4-5-4-1.824 0-3.56 2.404-3.928 5.003A1 1 0 0117 26v3h2a1 1 0 011 1c0 2.487-1.009 4-3 4a1 1 0 010-2c.496 0 .802-.262.93-1H16a1 1 0 01-1-1v-3a1 1 0 01-1-1c0-3.912 2.601-8 6-8 4.833 0 7 1.625 7 6 0 1.964-.305 3.653-.925 5.063l.874 2.62z"
        fill="#FFF"
        fillRule="nonzero"
        mask="url(#prosperity_svg__b)"
      />
    </g>
  </svg>
);

export default SvgProsperity;
