import React from 'react';
import './About.css';

import aboutImage from '../../images/about-illustration.png';

export const About = () => {

  return (
    <React.Fragment>

      <div className="page_title full_w">
        <div className="container">
          <h1>About</h1>
        </div>
      </div>

      <main id="main_content">

        <div className="max_w_content">

          <figure className="top">
            <img src={aboutImage} alt="About Illustration" />
          </figure>          

          <h2 className="huge_title_dark">Until Every Tribe, Tongue, and Nation Has Heard</h2>

          <br />
          <br />

          <p className="lw">Information is powerful, and over the years, the circulation of information has ignited countless mission movements. ﻿Since their first 32-page prayer guide in 1964, <i>Operation World</i> has been informing and inspiring Christians around the world to join God in praying for the nations. For more than twenty years, the Joshua Project has helped awaken the church by defining the unfinished task of the Great Commission among the unreached. Both of these great resources, and others like them, have contributed to mobilizing laborers, guiding mission strategies, and awakening countless hearts to God’s global purpose.</p>

          <br />
          <br />

          <p className="mw large">The Mobilization Index joins <i>Operation World</i>, the Joshua Project, and other mission research initiatives in furthering the cause of frontier missions. Specifically, the Mobilization Index identifies the countries and locations around the world that have the greatest mission sending potential, indicating locations where the church is ripe for mobilization. ﻿By recognizing these strategic locations and profiling their unique ﻿potential, we hope to see new and renewed mission mobilization and mission sending movements. <strong>The goal of the Mobilization Index is simple: to see an increase in global mobilization that leads to more laborers being sent to the unreached to proclaim Christ until every tribe, tongue, and nation has heard.</strong></p>

          <br />
          <br />

          <p className="lw">Currently, the Index consists of 60 locations that have more than one million evangelicals or are strategically situated. We believe that each of these locations has the unique potential to raise up and send out laborers for God’s Harvest. <strong>The Mobilization Index is not a ranking of which countries are most important for mobilization or which can send the most missionaries. Each of the 60 nations on the Index has unique mission sending potential.</strong> Each of these 60 nations will face their own unique sets of opportunities and obstacles as they raise up and send out missionaries. And each of these 60 nations is worthy of our prayers.</p>
          <p className="lw">The Mobilization Index desires to be a tool that mobilizers and church leaders can use to fuel mission prayer, encourage a greater commitment to the unreached from all believers, inform leaders about the current sta te of sending, and guide the placement of future missionaries. We pray that God uses the Mobilization Index in your life as we all endeavor to complete the Great Commission.</p>

          <br />
          <br />

        </div>

      </main>

    </React.Fragment>
  );
}  

