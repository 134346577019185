export const PATH_IMG_BASE = 'http://mobindex.mobilization.org/images/';
export const PATH_BASE = 'https://mobindex.herokuapp.com';
export const PATH_COUNTRIES = '/countries.json';
export const PATH_PROFILES = '/country_profiles.json';
export const PATH_FOOTNOTES = '/footnotes.json';
// export const PARAM_SEARCH = 'query=';
// export const PARAM_PAGE = 'page=';

export const REGIONS = {
  7: 'East and Southern Africa',
  6: 'North and Middle East Africa',
  8: 'West and Central Africa',
  11: 'Latin America',
  12: 'North and Caribbean America',
  5: 'Central Asia',
  3: 'Northeast Asia',
  4: 'South Asia',
  2: 'Southeast Asia',
  1: 'Australia and Pacific',
  9: 'Eastern Europe and Eurasia',
  10: 'Western Europe',
};

export const strip = (str) => str.toLowerCase().replace(/[^A-Z0-9]/ig, '');