import React from "react";

const SvgCustomMobPotential = props => (
  <svg width={48} height={48} {...props}>
    <defs>
      <circle id="custom-mob-potential_svg__a" cx={24} cy={24} r={24} />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="custom-mob-potential_svg__b" fill="#fff">
        <use xlinkHref="#custom-mob-potential_svg__a" />
      </mask>
      <use
        fill="#D8D8D8"
        fillRule="nonzero"
        xlinkHref="#custom-mob-potential_svg__a"
      />
      <g
        mask="url(#custom-mob-potential_svg__b)"
        fill="#FFB134"
        fillRule="nonzero"
      >
        <path d="M-60-22h160v80H-60z" />
      </g>
      <path
        d="M13 16a1 1 0 010-2h22a1 1 0 010 2H13zM13 34a1 1 0 010-2h22a1 1 0 010 2H13zM9 25a1 1 0 010-2h30a1 1 0 010 2H9z"
        fill="#FFF"
        fillRule="nonzero"
        mask="url(#custom-mob-potential_svg__b)"
      />
      <path
        d="M30 19a4 4 0 110-8 4 4 0 010 8zM25 37a4 4 0 110-8 4 4 0 010 8zM16 28a4 4 0 110-8 4 4 0 010 8z"
        fill="#FFB134"
        fillRule="nonzero"
        mask="url(#custom-mob-potential_svg__b)"
      />
      <path
        d="M30 19a4 4 0 110-8 4 4 0 010 8zm0-2a2 2 0 100-4 2 2 0 000 4zM25 37a4 4 0 110-8 4 4 0 010 8zm0-2a2 2 0 100-4 2 2 0 000 4zM16 28a4 4 0 110-8 4 4 0 010 8zm0-2a2 2 0 100-4 2 2 0 000 4z"
        fill="#FFF"
        fillRule="nonzero"
        mask="url(#custom-mob-potential_svg__b)"
      />
    </g>
  </svg>
);

export default SvgCustomMobPotential;
