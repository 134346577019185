import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from 'react-router-dom';
import { Home } from './components/Home/Home'
import { Compare } from './components/Compare/Compare'
import { Profile } from './components/Profile/Profile'
import { Header } from './components/layout/Header/Header'
import { ScrollToTop } from './components/layout/Header/ScrollToTop'
import { Footer } from './components/layout/Footer/Footer'
import { Nav } from './components/layout/Nav/Nav';
import { About } from './components/About/About'
import { Map } from './components/Map/Map'
import { Sources } from './components/Sources/Sources'
import { Definitions } from './components/Definitions/Definitions'
// import { Contact } from './components/Contact/Contact'

import { Button } from './components/Button/Button'
import classNames from 'classnames';
import Drawer from '@material-ui/core/Drawer';

import './App.css';

import {
  PATH_BASE,
  PATH_COUNTRIES,
  PATH_PROFILES,
  PATH_FOOTNOTES
} from './constants';

export const App = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [profiles, setProfiles] = useState([]);
  const [countries, setCountries] = useState([]);
  const [footnotes, setFootnotes] = useState([]);
  const [profilesError, setProfilesError] = useState(null);
  const [countriesError, setCountriesError] = useState(null);
  const [footnotesError, setFootnotesError] = useState(null);
  const [redirectLoc, setRedirectLoc] = useState(null);

  const drawerClass = classNames(
    { 'open': isDrawerOpen }
  );  

  async function fetchCountries() {
    console.log("********************** FETCHING COUNTRIES WITH API ***************************************")
    const url = `${PATH_BASE}${PATH_COUNTRIES}`;
    const res = await fetch(url);
    res
      .json()
      .then(res => setCountries(res))
      .catch(err => setCountriesError(err));
  }
  async function fetchProfiles() {
    console.log("********************** FETCHING PROFILES WITH API ***************************************")
    const url = `${PATH_BASE}${PATH_PROFILES}`;
    const res = await fetch(url);
    res
      .json()
      .then(res => setProfiles(res))
      .catch(err => setProfilesError(err));
  }
  async function fetchFootnotes() {
    console.log("********************** FETCHING FOOTNOTES WITH API ***************************************")
    const url = `${PATH_BASE}${PATH_FOOTNOTES}`;
    const res = await fetch(url);
    res
      .json()
      .then(res => setFootnotes(res))
      .catch(err => setFootnotesError(err));
  }

  useEffect(() => {
    fetchCountries();
    fetchProfiles();
    fetchFootnotes();
  }, []);

  const setRedirect = (event) => {
    console.log("setRedirect");
    console.log(event);
    setRedirectLoc(event.value);
    // history && history.push(event.value);
  };

  const sideList = () => (
    <div
      role="presentation"
    >
      <div className="header_menu_button flex">
        <Button 
          onClick={onDrawerToggle(false)}
          className="toggleButton"
        >
          <div id="main-menu-toggle" className={drawerClass}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </Button>
        <span id="main-menu-label" className="mobile_hidden">{ isDrawerOpen ? "Close" : "Menu" }</span>
      </div>

      <div className="drawer-nav">
        <div className="DrawerTitle" onClick={onDrawerToggle(false)}>
          <Link to="/">Mobilization Index</Link>
        </div>
        <Nav profiles={profiles} onDrawerToggle={onDrawerToggle(false)} onProfileChange={setRedirect} />
      </div>

    </div>
  );

  const onDrawerToggle = (open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setIsDrawerOpen(open);
  };

  return (
    <Router>
      <ScrollToTop />

      { redirectLoc &&
        <Redirect to={redirectLoc} />
      }

      <div className="App grid">
        {/* <SideDrawer show={isDrawerOpen}/> */}
        <Drawer open={isDrawerOpen} onClose={onDrawerToggle(false)}>
          {sideList('left')}
        </Drawer>

        <Header 
          onDrawerToggle={onDrawerToggle} 
          isDrawerOpen={isDrawerOpen}
        />

        <Switch>
          <Route exact path='/'>
            <Home />
          </Route>

          { countriesError
          ? <div>
              <p>Failed to retrieve country data from server. Please try again later.</p>
            </div>
          : 
          countries && 
            <Route 
              path="/compare" 
              render={(props) => <Compare countries={countries} {...props} />} 
            />
          }              

          { profilesError
          ? <div>
              <p>Failed to retrieve country profile data from server. Please try again later.</p>
            </div>
          : 
          profiles && countries && footnotes &&
            <Route 
              path="/profile/:countryName" 
              render={(props) => <Profile profiles={profiles} countries={countries} footnotes={footnotes} {...props} />} 
            />
          } 

          <Route path='/about'><About /></Route>
          <Route path='/map'><Map /></Route>
          <Route path='/sources'><Sources /></Route> 
          <Route path='/definitions'><Definitions /></Route> 
          {/* <Route path='/contact'><Contact /></Route>  */}

        </Switch>
        
        <Footer profiles={profiles} onDrawerToggle={onDrawerToggle(false)} onProfileChange={setRedirect} />
      </div>
    </Router>
  );
  
}

export default App;
