import React from 'react';
import './Sources.css';

export const Sources = () => {

  return (
    <React.Fragment>

<div className="page_title full_w">
      <div className="container">
        <h1>Sources</h1>
      </div>
    </div>

      <main id="main_content">

        <div className="max_w_content">
          <br />
          <br />
          <br />
          <p>Our goal with the Mobilization Index is to capture as complete and accurate of a profile as possible for each of the nations on the list. To do this, we gathered data from a variety of sources highlighting a wide range of factors. The data used in the Mobilization Index comes from several reputable and trustworthy sources, so we feel confident that our conclusions are based on solid information. When looked at holistically, these sub-indexes bring to light the unique role that each nation has in seeing Christ proclaimed among all peoples.</p>
          <br />
          <br />
          <br />
        </div>

        <div className="sources">

          <div className="source">
            <h4><i>Operation World</i></h4>
            <div className="icon_text flex">
              <div className="dot evangelicals"></div>
              <div className="text">
                <h3>Total number of Evangelicals</h3>
              </div>
            </div>
            <div className="icon_text flex">
              <div className="dot sending"></div>
              <div className="text">
                <h3>Current sending abroad</h3>
              </div>
            </div>
            <p>Called the Definitive Prayer Guide, believers for decades have been using OW to guide their prayer for nations. The Mobilization Index utilizes OW for two critical pieces of data: numbers of evangelicals and numbers of missionaries sent. While the most current 7th edition is almost ten years old (published in 2010) OW still represents one of the most trustworthy and respected sources for country demographics.</p>
            <p><a href="http://www.operationworld.org/" target="_blank" rel="noopener noreferrer">operationworld.org</a></p>
          </div>

          <div className="source">
            <h4>Joshua Project</h4>
            <div className="icon_text flex">
              <div className="dot regional"></div>
              <div className="text">
                <h3>Regional Access to UPGs</h3>
              </div>
            </div>
            <p>According to their website, “Joshua Project is a research initiative seeking to highlight the ethnic people groups of the world with the fewest followers of Christ.” The desire of Joshua Project is to see pioneer church planting movements among every people group, especially those groups with little to no Christians among them. The Mob Index uses the Joshua Project for numbers of unreached and unengaged for the 60 locations on the Index. Specifically, we measure the in-country and regional UPG access for each Mob Index country. In other words, how far does a mobilized Christian have to travel before they encounter the unreached? For Christians from some nations, this involves getting on an airplane while others just walk across the street.</p>
            <p><a href="https://joshuaproject.net" target="_blank" rel="noopener noreferrer">joshuaproject.net</a></p>
          </div>


          <div className="source">
            <h4>Legatum Institute</h4>
            <div className="icon_text flex">
              <div className="dot prosperity"></div>
              <div className="text">
                <h3>Prosperity</h3>
              </div>
            </div>
            <p>A nation’s prosperity should be considered when profiling mobilization and sending potential. But prosperity is more than just economic wealth. Therefore, we chose to use data from Legatum’s Prosperity Index. Legatum determines overall prosperity through what they call the “nine pillars” of prosperity that considers factors such as economic quality, education, health, and governance. Early on, we observed a correlation between a nation’s prosperity level and their current mission sending. The more prosperous a nation, the more missionaries they were able to send. However, this only applied to external sending. Prosperity does not appear to be a factor when looking at internal mission sending. This is an important factor to consider when a Mob Index country has low prosperity but high in-country access to the unreached.</p>
            <p><a href="https://www.prosperity.com/" target="_blank" rel="noopener noreferrer">prosperity.org</a></p>
          </div>

          <div className="source">
            <h4>Geert Hofstede</h4>
            <div className="icon_text flex">
              <div className="dot bridges"></div>
              <div className="text">
                <h3>Cultural Bridges to UPGs</h3>
              </div>
            </div>
            <p>Cultural distance to the unreached was another factor we felt highlighted each nation’s mobilization and sending potential. To do this, we used Geert Hofstede’s research into the six dimensions of national culture. By comparing the cultural data from each nation on the Mobilization Index with a list of countries with large populations of UPGs, we were able to determine most nations overall cultural distance. Some locations, like Tanzania and Brazil, are very close culturally to the unreached. While other countries, Australia and the U.K., are further away culturally. We want to be clear that cultural distance should not be used to determine where a nation should send missionaries, nor does it determine who will be successful and who won’t. But cultural distance can be used as a way of opening the eyes of Christians by showing them that maybe the unreached are not so diﬀerent after all. Perhaps, the way a Brazilian was raised and looks at the world is similar to that of an Arab Muslim, and these commonalities could be a bridge to a gospel friendship.</p>
            <p><a href="https://geerthofstede.com/" target="_blank" rel="noopener noreferrer">geerthofstede.com</a></p>
          </div>

          <div className="source">
            <h4>Pew Research Center</h4>
            <div className="icon_text flex">
              <div className="dot freedom"></div>
              <div className="text">
                <h3>Religious Freedom</h3>
              </div>
            </div>
            <p>Religious restrictions can potentially play a significant role in a nation’s ability to mobilize and send out workers to the unreached. The Pew Forum has researched both government restrictions and social hostilities and how they interfere with religious beliefs and practices. Nations like South Africa and Brazil have virtually no restrictions, while other countries, China and Indonesia being two examples, severally restrict their population’s religious freedoms. Using this data, we were able to determine to what degree the nations on the Mobilization Index are religiously restricted. This will no doubt inﬂuence the mobilization and sending methods the church employs. For our analysis, we chose to weigh government restrictions more than social hostilities believing that the restrictions a government place on  the free exercise of Christianity would have a greater impact on the Christians mobilizing other Christians than the harassment of private individuals.</p>
            <p><a href="https://www.pewforum.org/" target="_blank" rel="noopener noreferrer">pewforum.org</a></p>
          </div>

        </div>

        <br />
        <h4 className="text_center">Other Sources used in Researching and Writing Profiles</h4>
        <br />
        <br />

        <div className="sources">
          
          <div className="source">
            <h5>Population Reference Bureau</h5>
            <p>We used the PRB’s World Population Data to determine percentage of the population under the age of 15%. In Uganda, for example, 49% of the population is under the age of 15 while in Brazil just 23% are 15 or younger. Countries with large populations under the age of 15 could impact the type of mobilization strategy employed.</p>
            <p><a href="https://www.prb.org/worldpopdata/" target="_blank" rel="noopener noreferrer">worldpopdata.org</a></p>
          </div>

          <div className="source">
            <h5>Charities Aid Foundation</h5>
            <p>The Mobilization Index also considers a nation’s overall generosity. The CAF World Giving Index measures a nation’s generosity in three areas: helping a stranger, donating money, and volunteering time. Looking at a nation’s overall Generosity score can provide mobilizers with insight into key areas that could impact long-term mission movements.</p>
            <p><a href="https://www.cafonline.org/" target="_blank" rel="noopener noreferrer">cafonline.org</a></p>
          </div>

        </div>

        <br />
        <h4 className="text_center">Note about Revising Data</h4>
        <br />

        <div className="max_w_content">
          <p>We strive to keep all our data up to date. Some sources used in the Mobilization Index update or revise their data on a yearly basis while others update less frequently. In each case, the data presented represents what we believe to be the most current, accurate, and trustworthy data available. However, if you know of another source of research, please <a href="#">contact us with your data</a> so that we can review, and if applicable, incorporate into the Mobilization Index.</p>
        </div>

        <br />
        <br />
        <br />

      </main>

    </React.Fragment>
  );
}  

