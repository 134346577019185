import React from "react";

const SvgMovementsIcon = props => (
  <svg width={48} height={48} {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M36.74 24.5c0 .089-.015.178-.044.268a.46.46 0 01-.18.224l-4.29 4.29c-.327.357-.662.364-1.006.02-.342-.342-.334-.679.022-1.005l3.039-3.084H21.74a.686.686 0 01-.512-.2.683.683 0 01-.204-.514c0-.21.068-.381.204-.512a.686.686 0 01.512-.201h12.54l-3.04-3.084c-.355-.327-.363-.663-.021-1.005.344-.344.679-.337 1.007.02l4.29 4.29c.088.059.147.136.179.224.029.09.045.179.045.268"
        fill="#8C9B94"
      />
      <circle stroke="#8C9B94" cx={24} cy={24} r={23.5} />
      <path
        d="M17.5 24.5a2.89 2.89 0 01-5.778 0 2.89 2.89 0 015.778 0z"
        stroke="#8C9B94"
        strokeWidth={1.444}
        strokeLinecap="round"
      />
    </g>
  </svg>
);

export default SvgMovementsIcon;
